import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import { COPILOTS_AND_AGENTS, HUBSPOT_URL } from "../../constants";
import LaunchIcon from "@mui/icons-material/Launch";

const PRIVACY_POLICY_LINK = "https://autonomize.ai/privacy-policy";

const Form = ({ setFormSubmitted }: { setFormSubmitted: any }) => {
  const [error, setError] = useState<any>(null);
  const [areasOfInterest, setAreasOfInterest] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const form = document.getElementById("updatesRequest") as HTMLFormElement;
    const formData = new FormData(form);
    formData.append("area_of_interest", JSON.stringify(areasOfInterest));

    try {
      const response = await fetch(HUBSPOT_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log(response);
        setIsSubmitting(true);
        setFormSubmitted(true);
        form.reset();
        setAreasOfInterest([]);
      }
    } catch (error) {
      setError(error);
      console.error("Error submitting the form:", error);
    }
  };

  const handleSelectAll = (e: any) => {
    const value = e.target.value;
    if (value.includes("all")) {
      setAreasOfInterest(COPILOTS_AND_AGENTS);
    } else if (value.includes("none")) {
      setAreasOfInterest([]);
    } else {
      setAreasOfInterest(value);
    }
  };

  return (
    <Box
      component="form"
      id="updatesRequest"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: 3,
        backgroundColor: "transparent",
      }}
      onSubmit={handleSubmit}
      noValidate={false}
    >
      <Box display="flex" justifyContent="space-between">
        <TextField label="First Name" variant="standard" size="small" sx={{ flex: 1 }} name="firstname" required />
        <TextField
          label="Last Name"
          variant="standard"
          size="small"
          sx={{ flex: 1, marginLeft: 2 }}
          name="lastname"
          required
        />
      </Box>
      <TextField label="Email" variant="standard" size="small" type="email" fullWidth name="email" required />
      <TextField label="Company" variant="standard" size="small" type="text" fullWidth name="company" required />{" "}
      <TextField label="Job Role" variant="standard" size="small" type="text" fullWidth name="job_role" required />
      <FormControl fullWidth variant="standard" size="small">
        <InputLabel id="areas-of-interest-label">Areas of Interest</InputLabel>
        <Select
          labelId="areas-of-interest-label"
          multiple
          value={areasOfInterest}
          onChange={handleSelectAll}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
              },
            },
          }}
        >
          {areasOfInterest?.length ? (
            <StyledMenuItem value="none">
              <em>Unselect All</em>
            </StyledMenuItem>
          ) : (
            <StyledMenuItem value="all">
              <em>Select All</em>
            </StyledMenuItem>
          )}
          {COPILOTS_AND_AGENTS.map((option) => (
            <StyledMenuItem key={option} value={option}>
              {option}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Checkbox name="checked" />}
        label={
          <>
            I agree to the{" "}
            <Link sx={{ color: "#4D129D", textDecoration: "none" }} href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
              Terms and Conditions <LaunchIcon sx={{ fontSize: 15 }} />
            </Link>
          </>
        }
      />
      {error && (
        <Typography variant="body1" color="error.main" component="p">
          "Something went wrong! Please try again."
        </Typography>
      )}
      <StyledButton disabled={isSubmitting} type="submit">
        {isSubmitting ? "Submitting..." : "Start for free"}
      </StyledButton>
    </Box>
  );
};

const StyledButton = styled(Button)({
  height: 40,
  padding: "0px 15px",
  gap: 4,
  borderRadius: "8px",
  border: `1px solid #4D129D`,
  variant: "contained",
  color: "#4D129D",
  size: "small",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4D129D",
    color: "#ffffff",
  },
});

const StyledMenuItem = styled(MenuItem)({
  color: "rgba(0, 0, 0, 0.6)",
  backgroundColor: "transparent",
  backdropFilter: "blur(10px)",
  "&.Mui-selected": {
    backgroundColor: "rgba(207, 194, 252, 0.3)",
    color: "rgba(0, 0, 0, 0.7)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "rgba(207, 194, 252, 0.3)",
  },
});

export default Form;
