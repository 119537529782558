import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";

const NavigateButton = ({ text, align, handleOnClick }: NavigateButtonProps) => {
  const buttonAlignment = align === "left" ? { left: "-10px" } : { right: "-10px" };

  return (
    <StyledButton variant="contained" color="secondary" onClick={handleOnClick} sx={{ ...buttonAlignment }}>
      {text ? text : align === "left" ? "<" : ">"}
    </StyledButton>
  );
};

const StyledButton = styled(Button)({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "16px",
  background: "#E5DEFD",
  padding: "5px !important",
  color: "grey",
  minWidth: "40px",
  fontWeight: "bold",
});

type NavigateButtonProps = {
  text?: string;
  handleOnClick: () => void;
  align: "left" | "right";
};

export default NavigateButton;
