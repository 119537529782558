import React from "react";
import { AppBar, Toolbar, Button, Box, Link, IconButton, Menu, MenuItem } from "@mui/material";
import AutonomizeLogo from "../../assets/images/AutonomizeLogo";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";
import BookADemo from "../book-a-demo/BookADemo";

const generateAutonomizeLinkWithId = (section: string) => `https://autonomize.ai#${section}`;

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [showBookDemoModal, setShowBookDemoModal] = React.useState(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StyledAutonomizeLogo />
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <LinkContainer>
              <StyledLink href={generateAutonomizeLinkWithId("copilot")}>Copilots</StyledLink>
              <StyledLink href={generateAutonomizeLinkWithId("platform")}>Platform</StyledLink>
              <StyledLink href={generateAutonomizeLinkWithId("trusted")}>Trusted AI</StyledLink>
              <StyledLink href={generateAutonomizeLinkWithId("company")}>Company</StyledLink>
              <StyledButton onClick={() => setShowBookDemoModal(true)}>Book a demo</StyledButton>
            </LinkContainer>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <StyledMobileMenuItem onClick={handleCloseNavMenu}>
                <StyledLink href={generateAutonomizeLinkWithId("copilot")}>Copilots</StyledLink>
              </StyledMobileMenuItem>
              <StyledMobileMenuItem onClick={handleCloseNavMenu}>
                <StyledLink href={generateAutonomizeLinkWithId("platform")}>Platform</StyledLink>
              </StyledMobileMenuItem>
              <StyledMobileMenuItem onClick={handleCloseNavMenu}>
                <StyledLink href={generateAutonomizeLinkWithId("trusted")}>Trusted AI</StyledLink>
              </StyledMobileMenuItem>
              <StyledMobileMenuItem onClick={handleCloseNavMenu}>
                <StyledLink href={generateAutonomizeLinkWithId("company")}>Company</StyledLink>
              </StyledMobileMenuItem>
              <StyledMobileMenuItem onClick={handleCloseNavMenu}>
                <StyledButton onClick={() => setShowBookDemoModal(true)}>Book a demo</StyledButton>
              </StyledMobileMenuItem>
            </Menu>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <BookADemo open={showBookDemoModal} handleClose={() => setShowBookDemoModal(false)} />
    </>
  );
};

const StyledAppBar = styled(AppBar)({
  background: "transparent",
  color: "#000000",
  boxShadow: "none",
  padding: "10px 0px",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const StyledAutonomizeLogo = styled(AutonomizeLogo)({
  width: 180,
  height: "auto",
  padding: "20px 0",
  maxWidth: "35vw",
});

const LinkContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
});

const StyledLink = styled(Link)({
  padding: "0px 8px",
  gap: 0,
  borderRadius: "8px 0px 0px 0px",
  opacity: 1,
  color: "#000000E5",
  textDecoration: "none",
  fontWeight: "400",
  letterSpacing: "0.5px",
  "&:hover": {
    opacity: 0.7,
  },
  "& .MuiLink-root": {
    textAlign: "left",
  },
});

const StyledButton = styled(Button)({
  height: 40,
  padding: "0px 15px",
  gap: 4,
  borderRadius: "8px",
  border: `1px solid #4D129D`,
  variant: "contained",
  color: "#4D129D",
  size: "small",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4D129D",
    color: "#ffffff",
  },
});

const StyledMobileMenuItem = styled(MenuItem)({
  color: "rgba(0, 0, 0, 0.6)",
  backgroundColor: "transparent",
  backdropFilter: "blur(10px)",
});

export default Header;
