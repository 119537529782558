import * as React from "react";
import Grid from "@mui/material/Grid2";
import TrialInfo from "../trial-info/TrialInfo";
import FormContainer from "../form/FormContainer";

export const Main = () => {
  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <TrialInfo />
      <FormContainer />
    </Grid>
  );
};
