import React from "react";
import { Container } from "@mui/material";
import { Main } from "./components/main/Main";
import Header from "./components/header/Header";
import styled from "@emotion/styled";

const HomePage: React.FC = () => {
  return (
    <StyledContainer maxWidth="xl">
      <Header />
      <Main />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  min-height: 100vh;
  padding-bottom: 30px;
`;

export default HomePage;
