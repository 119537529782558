import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import { Typography, Box, Button } from "@mui/material";
import Form from "./Form";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const FormContainer = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const renderForm = () => {
    if (!formSubmitted) {
      return <Form setFormSubmitted={setFormSubmitted}/>;
    }

    return (
      <>
        <Box sx={{ textAlign: "center", margin: "100px 0" }}>
          <Typography variant="h4" sx={{ color: "#3e3e53", fontWeight: "bold" }}>
            Thank You for Signing Up!
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "20px" }}>
            Your trial account is being created. You will receive the details shortly in your email.
          </Typography>
        </Box>
        <StyledButton onClick={() => setFormSubmitted(false)}>Request another trial</StyledButton>
      </>
    );
  };

  return (
    <Grid container size={{ sm: 12, lg: 6 }} alignItems={"center"}>
      <StyledItem>
        <Typography variant="h5" gutterBottom mt={2}>
          Test Drive our AI Agents and Copilots
        </Typography>
        {renderForm()}
      </StyledItem>
    </Grid>
  );
};

const StyledItem = styled(Item)({
  width: "80%",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(5px)",
  height: "max-content",
  minHeight: "60%",
  borderRadius: "10px",
  margin: "0px auto",
  display: "flex",
  flexDirection: "column",
});

const StyledButton = styled(Button)({
  height: 40,
  padding: "0px 15px",
  gap: 4,
  borderRadius: "8px",
  border: `1px solid #4D129D`,
  variant: "contained",
  color: "#4D129D",
  size: "small",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4D129D",
    color: "#ffffff",
  },
  margin: "0 auto",
  marginTop: "auto",
  width: "max-content",
});

export default FormContainer;
