import React from "react";

const AutonomizeLogo = (props: any) => {
  return (
    <img
      src="https://cdn.autonomize.dev/autonomize-website/assets/images/v2/logo-black.png"
      alt="Autonomize Logo"
      {...props}
    />
  );
};

export default AutonomizeLogo;
