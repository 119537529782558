export const HUBSPOT_URL = "https://forms.hubspot.com/uploads/form/v2/20718505/cbfa7474-5100-4836-b25d-5f98b28acaed";

export const COPILOTS_AND_AGENTS = [
  "Prior Authorization Copilot",
  "Medical Chart Review copilot (Wizard)",
  "HEDIS Care Gaps copilot",
  "Clinical Research copilot (Pixel)",
  "Multi-document Search and QA",
  "Provider Contracts Copilot",
  "Fee Schedule Update Copilot",
  "Payment Integrity Copilot",
  "Integration Agents",
  "Privacy & Security Agents",
  "Chart Extraction Agents",
  "Disease Specific Insights Agents",
  "Data Extraction Agents",
  "Prior Authorization Agents",
  "Care Management Agents",
  "Search & Data Mining Agents",
  "Generative AI Agents",
  "Clinical Research Agents",
  "Knowledge Management Agents",
  "HEDIS Care Gap Agents",
  "Medical Record Review Agents",
  "Claims / Payment Integrity Agents",
];

export const CAROUSEL_DATA = [
  { link: "https://cdn.autonomize.dev/autonomize-website/assets/images/GenesisWeb.mp4", type: "video" },
  //   { link: "https://picsum.photos/700/300", type: "image" }, // Images can be added in the future
  { link: "https://cdn.autonomize.dev/autonomize-website/assets/images/pipeline-video.mp4", type: "video" },
];
