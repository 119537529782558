import { styled, Paper, Grid2 as Grid, Typography, Box, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import NavigateButton from "../navigate-button/NavigateButton";
import { CAROUSEL_DATA } from "../../constants";

const TrialInfo = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % CAROUSEL_DATA.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + CAROUSEL_DATA.length) % CAROUSEL_DATA.length);
  };

  return (
    <Grid size={{ sm: 12, lg: 6 }}>
      <Item>
        <Typography variant="h5" gutterBottom>
          Augment knowledge work, Reduce Administrative burden
        </Typography>
        <Typography variant="body1" gutterBottom>
          Test Drive our AI Agents and Copilots
        </Typography>
      </Item>
      <Item>
        <Box
          sx={{
            position: "relative",
            backgroundColor: "white",
            borderRadius: "5px",
            boxShadow: "0px 5px 10px rgba(0,0,0,0.1)",
            padding: "5px",
            maxWidth: "700px",
            margin: "auto",
          }}
        >
          {CAROUSEL_DATA[currentIndex].type === "image" ? (
            <img
              src={CAROUSEL_DATA[currentIndex].link}
              alt="Autonomize Solutions and Technology"
              style={{ objectFit: "contain", height: "400px", width: "100%" }}
            />
          ) : (
            <StyledIframe
              src={CAROUSEL_DATA[currentIndex].link}
              title="Autonomize Solutions and Technology"
              frameBorder="0"
              allowFullScreen
              style={{ objectFit: "contain", width: "100%" }}
            />
          )}
          <NavigateButton align="left" handleOnClick={handlePrev} />
          <NavigateButton align="right" handleOnClick={handleNext} />
        </Box>
      </Item>
      <Item>
        <Typography variant="h6" gutterBottom>
          Welcome to Autonomize AI Test Drive sandbox
        </Typography>
        <Typography variant="body2" gutterBottom>
          Some things to keep in mind
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="This edition is for evaluation purposes only and your account will be removed at the end of the trial period. For enterprise deployments, please contact your Autonomize AI Sales contact." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Please do not upload any images or documents that contain PHI or PII information." />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <>
                  Reach out for any questions or support at{" "}
                  <a href="mailto:trial@autonomize.ai">trial@autonomize.ai</a>
                </>
              }
            />
          </ListItem>
        </List>
      </Item>
    </Grid>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  boxShadow: "none",
}));

const StyledIframe = styled("iframe")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
  [theme.breakpoints.up("md")]: {
    height: "350px",
  },
}));

export default TrialInfo;
