import React, { useState } from "react";
import { Modal, Typography, IconButton, TextField, Button, Grid2 as Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";

const BookADemo = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const [formSubmission, setFormSubmission] = useState({
    isSubmitting: false,
    submitted: false,
    error: "",
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setFormSubmission({ isSubmitting: true, submitted: false, error: "" });

    const form = document.getElementById("demoForm") as HTMLFormElement;
    const formData = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setFormSubmission({ isSubmitting: false, submitted: true, error: "" });
        form.reset();
      } else {
        console.log(response);
        throw new Error("Form submission failed");
      }
    } catch (error: any) {
      setFormSubmission({
        isSubmitting: false,
        submitted: false,
        error: "Oops, something went wrong and your request could not be processed. Please try again.",
      });
      console.error("Form submission failed", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledModalBox>
        <Grid container justifyContent="space-between" alignItems="center" mb={3}>
          <Typography id="modal-title" variant="h6" component="h2" className="text-center">
            Schedule a Demo
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <form
          id="demoForm"
          className="request-form"
          action="https://forms.hubspot.com/uploads/form/v2/20718505/38985265-7157-4b41-a15b-8ffa0c41b83b"
          method="POST"
          onSubmit={handleSubmit}
        >
          <TextField required fullWidth name="firstname" label="Full Name" variant="outlined" margin="normal" />
          <TextField fullWidth name="phone" label="Phone number" variant="outlined" margin="normal" />
          <TextField
            required
            fullWidth
            name="email"
            label="Work email"
            type="email"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            name="message"
            label="Brief Message (Describe your interest, use case or need)"
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
          />
          {formSubmission.submitted && (
            <Typography mt={1} color="success.main">
              Thank you. We will reach out to you to schedule a demo.
            </Typography>
          )}
          {formSubmission.error && (
            <Typography mt={1} color="error.main">
              {formSubmission.error}
            </Typography>
          )}
          <Box textAlign="right" mt={2}>
            <StyledButton type="submit" disabled={formSubmission.isSubmitting}>
              {formSubmission.isSubmitting ? "Submitting..." : "Submit"}
            </StyledButton>
          </Box>
        </form>
      </StyledModalBox>
    </Modal>
  );
};

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "rgba(255, 255, 255, 1)",
  padding: "24px",
  borderRadius: "10px",
  minWidth: "300px",
  maxWidth: "500px",
}));

const StyledButton = styled(Button)({
  height: 40,
  padding: "0px 15px",
  gap: 4,
  borderRadius: "8px",
  border: `1px solid #4D129D`,
  variant: "contained",
  color: "#4D129D",
  size: "small",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#4D129D",
    color: "#ffffff",
  },
});

export default BookADemo;
